import ryzen5600X from "../images/products/Meat.png";
import ryzen5800X from "../images/products/Meat-1.png";
import ryzen5900X from "../images/products/Meat-2.png";
import ryzen5950X from "../images/products/Meat-3.png";
import prevRyzen5600X from "../images/products/Meat.png";
import prevRyzen5800X from "../images/products/Meat-1.png";
import prevRyzen5900X from "../images/products/Meat-2.png";
import prevRyzen5950X from "../images/products/Meat-3.png";
import ryzen5600XGallery1 from "../images/products/Meat.png";
import ryzen5800XGallery1 from "../images/products/Meat-1.png";
import ryzen5900XGallery1 from "../images/products/Meat-2.png";
import ryzen5950XGallery1 from "../images/products/Meat-3.png";
const processors = [
  {
    category: "Meat",
    categoryId: "meat",
    id: "amd-ryzen-5-5600x",
    name: "Factory Meat",
    price: 20.15,
    image: ryzen5600X,
    previewImage: prevRyzen5600X,
    gallery: [ryzen5600XGallery1],
  },
  {
    category: "Meat",
    categoryId: "meat",
    id: "amd-ryzen-7-5800x",
    name: "Meat with Basil Topping",
    price: 20.15,
    image: ryzen5800X,
    previewImage: prevRyzen5800X,
    gallery: [ryzen5800XGallery1],
  },
  {
    category: "Meat",
    categoryId: "meat",
    id: "amd-ryzen-9-5900x",
    name: "Double Meat Bundle",
    price: 20.15,
    image: ryzen5900X,
    previewImage: prevRyzen5900X,
    gallery: [ryzen5900XGallery1],
  },
  {
    category: "Meat",
    categoryId: "meat",
    id: "amd-ryzen-9-5950x",
    name: "Double Meat Bundle With Basil",
    price: 20.15,
    image: ryzen5950X,
    previewImage: prevRyzen5950X,
    gallery: [ryzen5950XGallery1],
  },
];

export default processors;

import React from 'react'
import {
    Box,
    Container,
    Row,
    Column,
    FooterLink,
    Heading,

  } from "./FooterStyles";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import styles from "../css/Footer.module.css";



  const Footer = () => {
    return (
      <Box>
        <h1 style={{ color: "white",
                     textAlign: "center",
                     marginTop: "-50px" }}>
          Sahara Marketplace
        </h1>
        <br />
        <br />


        <Container>
          <Row>
            <Column>
              <Heading>Sahara</Heading>
              <FooterLink href="#">Aim</FooterLink>
              <FooterLink href="#">Vision</FooterLink>
              <FooterLink href="#">Testimonials</FooterLink>
            </Column>
            <Column>
              <Heading>Useful Links</Heading>
              <Link style={{color: 'GrayText', textDecoration: "none", marginBottom: "20px"}} to="/Word">Terms & Conditions</Link>
              <Link style={{color: 'GrayText', textDecoration: "none", marginBottom: "20px"}}  to="/Terms">Privacy Policy</Link>
              <Link style={{color: 'GrayText', textDecoration: "none", marginBottom: "20px"}} to="/Contact">Sign Up</Link>
            </Column>
            <Column>
              <Heading>Follow Us</Heading>
              <FooterLink href="https://facebook.com">
                <i className="fab fa-facebook-f">
                  <span style={{ marginLeft: "10px" }}>
                    Facebook
                  </span>
                </i>
              </FooterLink>
              <FooterLink href="https://instagram.com">
                <i className="fab fa-instagram">
                  <span style={{ marginLeft: "10px" }}>
                    Instagram
                  </span>
                </i>
              </FooterLink>
              <FooterLink href="https://twitter.com">
                <i className="fab fa-twitter">
                  <span style={{ marginLeft: "10px" }}>
                    Twitter
                  </span>
                </i>
              </FooterLink>
              <FooterLink href="https://youtube.com">
                <i className="fab fa-youtube">
                  <span style={{ marginLeft: "10px" }}>
                    Youtube
                  </span>
                </i>
              </FooterLink>
            </Column>
          </Row>
        </Container>
      </Box>

    );
  };
  export default Footer;


// function Header(props) {
//   const { openSearch, openCart } = props;
//   const [theme, setTheme] = useState(null);
//   const location = useLocation();

//   useEffect(() => {
//     setTheme(
//       location.pathname === "/" ||
//         location.pathname === "/"
//         ? "light"
//         : "dark"
//     );
//   }, [location]);

//   return (
//     <header className={styles.container}>
//       <nav className={styles.nav}>
//         <Link
//                   className={styles.media}
//           to=""
//         >
//          Vision
//         </Link>
//         <Link
//                   className={styles.media}
//           to="/Contact"
//         >
//           Sign Up
//         </Link>
//         <Link
//                   className={styles.media}
//           to="/Contact"
//         >
//           Sign Up
//         </Link>
//         <Link
//          className={styles.media}
//           to="/Terms"
//         >
//           Privacy Policy
//         </Link>
//         <div className={styles.loo}>
//         <i className="fab fa-youtube" style={{marginTop: "20px"}}>
//               <span style={{ marginLeft: "10px" }}>
//                    Youtube
//                </span>
//                </i>
//                <i className="fab fa-instagram">
//                   <span style={{ marginLeft: "10px" }}>
//                     Instagram
//                   </span>
//                </i>
//                <i className="fab fa-facebook-f">
//                    <span style={{ marginLeft: "10px" }}>
//                    Facebook
//                   </span>
//                </i>
//         <i className="fab fa-twitter">
//                   <span style={{ marginLeft: "10px" }}>
//                    Twitter
//                   </span>
//                  </i>
//         </div>
//         <p className={styles.right}>Copyright 2021 Saharagroup</p>
//       </nav>
//     </header>
//   );
// }

// Header.propTypes = {
//   openSearch: PropTypes.func.isRequired,
//   openCart: PropTypes.func.isRequired,
// };

// export default Header;

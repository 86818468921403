import React from 'react';
import '../word.css';

const Word = () => {
    return (
        <div>

            <p className="word">
            Terms of Service
            <br /><br />
Date: July 15, 2021
The services offered by Saharagroup under the Terms of Service include various products and services to help you sell goods and services to buyers, whether online by enabling you to create and build your own online store, in person or both. Any such services offered by Saharagroup are referred to in these Terms of Services as the “Services”. 
Any new features or tools which are added to the current Services shall be also subject to the Terms of Service. Saharagroup reserves the right to update and change the Terms of Service by posting updates and changes to the Saharagroup website. You are advised to check the Terms of Service from time to time for any updates or changes that may impact you and if you do not accept such amendments, you must cease using the Services.
By signing up with Sarahgroup or by using any Saharagroup Services, you are agreeing to be bound by the following terms and conditions (the “Terms of Service”).
<br /><br />
SAHARAGROUP RIGHTS
<br /><br />
We reserve the right to modify or terminate the Services for any reason, without notice at any time. Not all Services and features are available in every jurisdiction and we are under no obligation to make any Services or features available in any jurisdiction.
We reserve the right to refuse service to anyone for any reason at any time.
Verbal or written abuse of any kind (including threats of abuse or retribution) of any Saharagroup customer, Saharagroup employee, member, or officer will result in immediate Account termination.
In the event of a dispute regarding Account ownership, we reserve the right to request documentation to determine or confirm Account ownership. Documentation may include, but is not limited to, a scanned copy of your business license, government issued photo ID, the last four digits of the credit card on file, your status as an employee of an entity, etc.
Saharagroup retains the right to determine, in our sole judgment, rightful Account ownership and transfer an Account to the rightful business owner. If we are unable to reasonably determine the rightful business owner, without prejudice to our other rights and remedies, Saharagroup reserves the right to temporarily disable an Account until resolution has been determined between the disputing parties.
<br /><br />
ACCOUNT TERMS FOR VENDORS
<br /><br />
To access and use the Services, you must register for a Saharagroup account (“Account”) by providing your full legal name, current address, phone number, a valid email address, and any other information indicated as required Saharagroup may reject your application for an Account, or cancel an existing Account, for any reason, in our sole discretion.
You must be the older of: (i) 18 years, or (ii) at least the age of majority in the jurisdiction where you reside and from which you use the Services to open an Account.
You confirm that you are receiving any Services provided by Saharagroup for the purposes of carrying on a business activity and not for any personal, household or family purpose. <br /><br />
You acknowledge that Saharagroup will use the email address you provide on opening an Account or as updated by you from time to time as the primary method for communication with you. You must monitor the primary Account email address you provide to Saharagroup and your primary Account email address must be capable of both sending and receiving messages. Your email communications with Saharagroup can only be authenticated if they come from your primary Account email address.
You are responsible for keeping your password secure. Saharagroup cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account and password.
A breach or violation of any term in the Terms of Service, including the AUP, as determined in the sole discretion of Saharagroup may result in an immediate termination of your Services.

<br /><br />
SHIPPING FEES
<br /><br />
We are able to offer free ground shipping (3 to 7 days)  for orders $50 or more and a standard ground shipping of $10.99 on all orders up to 1.5kg within Canada. At the moment, we only ship to Canadian adress. If you live within the Edmonton area, we could also offer free delivery within 24 hours to 72 hours depending on weather and other conditions for orders over $50. We strive to get the product to you as quickly as possible and avoid delays.
<br /><br />
EXCHANGES & RETURNS
<br /><br />
At Saharagroup, customer satisfaction is very important to us and we stive to make sure all of our customers are happy with any purchase  made on this website. Because of this, we are happy to do a full refund of the purchase price for any item bought on this website. In you have any item you would like to return, please send us a message through our website at saharagroup361@gmail.ca or send us an email: Your satisfaction is our  priority and so we are always willing to work with you to get the best outcome.
If you received a damaged item please report the issue to us within 7 days of receiving your order and we will send you a replacement product at no cost to you. We will request a picture of the damaged item to better serve you in the future. Send an email to our customer service department at  with the details, including the order number.
We do not offer replacements for incorrect addresses or shipments that are updated as delivered in the tracking. However, we will do our best to help resolve any such issues. 
<br /><br />
CONFIDENTIALITY
<br /><br />
“Confidential Information” shall include, but shall not be limited to, any and all information associated with a party’s business and not publicly known, including specific business information, technical processes and formulas, software, customer lists, prospective customer lists, names, addresses and other information regarding customers and prospective customers, product designs, sales, costs (including any relevant processing fees), price lists, and other unpublished financial information, business plans and marketing data, and any other confidential and proprietary information, whether or not marked as confidential or proprietary. Saharagroup’s Confidential Information includes all information that you receive relating to us, or to the Services, that is not known to the general public including information related to our security program and practices.
 
<br /><br />
LIMITATION OF LIABILITY
<br /><br />
You expressly understand and agree that, to the extent permitted by applicable laws, Saharagroup shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses resulting from the use of or inability to use the Service.
To the extent permitted by applicable laws, in no event shall Saharagroup or our suppliers be liable for lost profits or any special, incidental or consequential damages arising out of or in connection with our site, our Services or these Terms of Service (however arising including negligence). You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, Saharagroup partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your breach of these Terms of Service or the documents it incorporates by reference (including the AUP), or your violation of any law or the rights of a third party.<br /><br />
Your use of the Services is at your sole risk. The Services are provided on an “as is” and “as available” basis without any warranty or condition, express, implied or statutory.
Saharagroup does not warrant that the Services will be uninterrupted, timely, secure, or error-free.
Saharagroup does not warrant that the results that may be obtained from the use of the Services will be accurate or reliable.
Saharagroup is not responsible for any of your tax obligations or liabilities related to the use of Saharagroup’s Services.
Saharagroup does not warrant that the quality of any products, services, information, or other materials purchased or obtained by you through the Services will meet your expectations, or that any errors in the Services will be corrected.
<br /><br />
WAIVER, SEVERABILITY, COMPLETE AGREEMENT 
<br /><br />
The failure of Saharagroup to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. If any provision of the Terms of Service, including all terms and conditions and other documents it incorporates by reference, is held by a court of competent jurisdiction to be contrary to law, such provision shall be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law, and the remaining provision of the Terms of Service shall remain in full force and effect.
The Terms of Service, including the documents it incorporates by reference, constitute the entire agreement between you and Saharagroup and govern your use of the Services and your Account, superseding any prior agreements between you and Saharagroup (including, but not limited to, any prior versions of the Terms of Service).
<br /><br />
PAYMENT OF FEES
<br /><br />
You will pay the Fees applicable to your subscription to Online Service and/or POS Services (“Subscription Fees”) and any other applicable fees, including but not limited to applicable fees relating to the value of sales made through your Store when using all payment providers other than Saharagroup Payments (“Transaction Fees”), and any fees relating to your purchase or use of any products or services such as Saharagroup Payments, POS Equipment, shipping, apps, Themes, domain names, Experts Marketplace, or Third Party Services (“Additional Fees”). Together, the Subscription Fees, Transaction Fees and the Additional Fees are referred to as the “Fees”.
You must keep a valid payment method on file with us to pay for all incurred and recurring Fees. Saharagroup will charge applicable Fees to any valid payment method that you authorize (“Authorized Payment Method”), and Saharagroup will continue to charge the Authorized Payment Method for applicable Fees until the Services are terminated, and any and all outstanding Fees have been paid in full. Unless otherwise indicated, all Fees and other charges are in U.S. dollars, and all payments shall be in U.S. currency.
Subscription Fees are paid in advance and will be billed in 30 day intervals (each such date, a “Billing Date”).
<br /><br /> Transaction Fees and Additional Fees will be charged from time to time at Saharagroup’s discretion. You will be charged on each Billing Date for all outstanding Fees that have not previously been charged. Fees will appear on an invoice, which will be sent to the Store Owner via the email provided. As well, an invoice will appear on the Account page of your Store’s administration console. Users have approximately two weeks to bring up and settle any issues with the billing of Subscription Fees.
If we are not able to process payment of Fees using an Authorized Payment Method, we will make a second attempt to process payment using any Authorized Payment Method 3 days later. If the second attempt is not successful, we will make a final attempt 3 days following the second attempt. If our final attempt is not successful, we may suspend and revoke access to your Account and the Services. Your Account will be reactivated upon your payment of any outstanding Fees, plus the Fees applicable to your next billing cycle. You may not be able to access your Account or your storefront during any period of suspension. If the outstanding Fees remain unpaid for 60 days following the date of suspension, Saharagroup reserves the right to terminate your Account.
You are responsible for all applicable Taxes that arise from or as a result of your subscription to or purchase of Saharagroup’s products and services. To the extent that Saharagroup charges these Taxes, they are calculated using the tax rates that apply based on the billing address you provide to us.
<br /><br /> Such amounts are in addition to the Fees for such products and services and will be billed to your Authorized Payment Method. If you are exempt from payment of such Taxes, you must provide us with evidence of your exemption, which in some jurisdictions includes an original certificate that satisfies applicable legal requirements attesting to tax-exempt status. Tax exemption will only apply from and after the date we receive evidence satisfactory to Saharagroup of your exemption. If you are not charged Taxes by Saharagroup, you are responsible for determining if Taxes are payable, and if so, self-remitting Taxes to the appropriate tax authorities in your jurisdiction.
For the avoidance of doubt, all sums payable by you to Saharagroup under these Terms of Service shall be paid free and clear of any deductions or withholdings whatsoever. Other than Taxes charged by Saharagroup to you and remitted to the appropriate tax authorities on your behalf, any deductions or withholdings that are required by law shall be borne by you and paid separately to the relevant taxation authority. Saharagroup shall be entitled to charge the full amount of Fees stipulated under these Terms of Service to your Authorized Payment Method ignoring any such deduction or withholding that may be required.
You are responsible for all applicable Taxes that arise from or because of any sale on your Saharagroup Store.
<br /><br />
You must maintain an accurate location in the administration menu of your Saharagroup Store. If you change jurisdictions, you must promptly update your location in the administration menu.
<br /><br />
CANCELLATION AND TERMINATION
<br /><br /> 
You may cancel your account and terminate the Terms of Service at any time by contacting us and then following the specific instructions indicated to you in Saharagroup’s response.
<br /><br />

CONTACT INFORMATION
<br /><br />
Our Privacy Policy governs Saharagroup’s use and collection of personal information. Additionally, if you or your customers are located in Europe, Saharagroup’s use and collection of European personal information is further governed by our Data Processing Addendum. For any privacy, issues contact us at saharagroup361@gmail.ca
            </p>
            
        </div>
    )
}

export default Word

import rtx3070 from "../images/products/Cream.jpg";
import rtx3080 from "../images/products/Cream1.jpg";
import prevRtx3070 from "../images/products/Cream.jpg";
import prevRtx3080 from "../images/products/Cream1.jpg";
import rtx3070Gallery1 from "../images/products/Cream.png";
import rtx3080Gallery1 from "../images/products/Cream1.png";

const Cream = [
  {
    category: "Cream",
    categoryId: "cream",
    id: "nvidia-geforce-rtx-3070",
    name: "Maquador Shea Butter Oil",
    price: 19.99,
    image: rtx3070,
    previewImage: prevRtx3070,
    gallery: [rtx3070Gallery1],
  },
  {
    category: "Cream",
    categoryId: "cream",
    id: "nvidia-geforce-rtx-3080",
    name: "Maquador Shea Butter Cream",
    price: 20.15,
    image: rtx3080,
    previewImage: prevRtx3080,
    gallery: [rtx3080Gallery1],
  },
];

export default Cream;

import fractalDesignMeshifyC from "../images/products/Service-1.jpg";
import nzxtH510Elite from "../images/products/Service-2.jpg";
import lianLiPc011Dynamic from "../images/products/Service-3.jpg";
import prevFractalDesignMeshifyC from "../images/products/Service-1.jpg";
import prevNzxtH510Elite from "../images/products/Service-2.jpg";
import prevLianLiPc011Dynamic from "../images/products/Service-3.jpg";
import fractalDesignMeshifyCGallery1 from "../images/products/Service-1.jpg";
import fractalDesignMeshifyCGallery2 from "../images/products/Service-4.jpg";
import nzxtH510EliteGallery1 from "../images/products/Service-2.jpg";
import lianLiPc011DynamicGallery1 from "../images/products/Service-3.jpg";

const chassis = [
  {
    category: "Services",
    categoryId: "services",
    id: "fractal-design-meshify-c",
    name: "Window Fixing",
    price: 20.15,
    image: fractalDesignMeshifyC,
    previewImage: prevFractalDesignMeshifyC,
    gallery: [
      fractalDesignMeshifyCGallery1,
      fractalDesignMeshifyCGallery2,
    ],
  },
  {
    category: "Services",
    categoryId: "services",
    id: "nzxt-h510-elite",
    name: "Floor Fixing",
    price: 20.15,
    image: nzxtH510Elite,
    previewImage: prevNzxtH510Elite,
    gallery: [nzxtH510EliteGallery1,],
  },
  {
    category: "Services",
    categoryId: "services",
    id: "lian-li-pc-011-dynamic",
    name: "Sink Fixing",
    price: 20.15,
    image: lianLiPc011Dynamic,
    previewImage: prevLianLiPc011Dynamic,
    gallery: [
      lianLiPc011DynamicGallery1,
    ],
  },
];

export default chassis;

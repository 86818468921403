import kingstonFuryDDR3 from "../images/products/Book-1.jpg";
import kingstonFuryDDR4 from "../images/products/Book-2.jpg";
import kingstonFuryDDR4rgb from "../images/products/Book-3.jpg";
import kingstonPredatorDDR4rgb from "../images/products/Book-5.jpg";
import prevKingstonFuryDDR3 from "../images/products/Book-1.jpg";
import prevKingstonFuryDDR4 from "../images/products/Book-2.jpg";
import prevKingstonFuryDDR4rgb from "../images/products/Book-3.jpg";
import prevKingstonPredatorDDR4rgb from "../images/products/Book-5.jpg";
import kingstonFuryDDR3Gallery1 from "../images/products/Book-1.jpg";
import kingstonFuryDDR4Gallery1 from "../images/products/Book-2.jpg";
import kingstonFuryDDR4RGBGallery1 from "../images/products/Book-3.jpg";
import kingstonPredatorDDR4RGBGallery1 from "../images/products/Book-5.jpg";

const memory = [
  {
    category: "Books",
    categoryId: "books",
    id: "kingston-hyperx-fury-ddr3",
    name: "7 Book Bundle",
    price: 20.15,
    image: kingstonFuryDDR3,
    previewImage: prevKingstonFuryDDR3,
    gallery: [
      kingstonFuryDDR3Gallery1,
    ],
  },
  {
    category: "Books",
    categoryId: "books",
    id: "kingston-hyperx-fury-ddr4",
    name: "Blue Book",
    price: 20.15,
    image: kingstonFuryDDR4,
    previewImage: prevKingstonFuryDDR4,
    gallery: [
      kingstonFuryDDR4Gallery1,
    ],
  },
  {
    category: "Books",
    categoryId: "books",
    id: "kingston-hyperx-fury-ddr4-rgb",
    name: "Book & Plant Bundle",
    price: 20.15,
    image: kingstonFuryDDR4rgb,
    previewImage: prevKingstonFuryDDR4rgb,
    gallery: [kingstonFuryDDR4RGBGallery1,],
  },
  {
    category: "Books",
    categoryId: "books",
    id: "kingston-hyperx-predator-ddr4-rgb",
    name: "Ocean Book",
    price: 20.15,
    image: kingstonPredatorDDR4rgb,
    previewImage: prevKingstonPredatorDDR4rgb,
    gallery: [
      kingstonPredatorDDR4RGBGallery1,
    ],
  },
];

export default memory;

import React from 'react';
import '../word.css';

const Terms = () => {
    return (
        <div>  
                  <p className="word">
                  Saharagroup Privacy Policy
                  <br />
                  <br />
Date: July 15, 2021
<br /><br />


Introduction 
<br />
<br />
Our main goal of bringing inclusive ecommerce business to you is to ensure that optimal services are delivered to all customers. The intent of this Privacy Policy is to help you to understand how we collect, use and share your personal information. This policy will be updated regularly, and this will occur when there is a change in business or practices and we will inform you at the earliest time. This Privacy Policy operates in conjunction with our Terms of Service and other agreements, which reference this Privacy Policy. Please review each of these documents to understand the terms and conditions of your agreements with us. 
<br /><br />
Collection:
<br />
<br />
We only collect what is needed in order to provide services. We delete information when it is no longer needed or anonymize this information to protect the privacy of the users. All our products and the websites are built with piracy and security principles with the intent of protecting the privacy of the users. In the nutshell, we collect and use the information with the guiding principle that your information belongs to you and we aim to only use your information to your benefit. Information will not be shared with third parties unless with your permission or when legally required. 
<br /><br />
Uses: 
<br />
<br />
At Sarahgroup we process your information when there is the need to complete a contractual obligation with a vendor or when a partner need to use your information related to their business. When doing business with international partners, we follow “legitimate interests” as defined by European law (insert a link). Based on these laws, we only process your personal information after a careful consideration of the potential risks to your privacy. In doing so, we are careful with whom we share information, duration of keeping the information and the technical measures we use to protect your information. 
<br /><br />

Your rights:
<br />
<br />
The information collected is your information and we believe that you should have access and the ability to control that information. The ability to collect, use and amend that information depends on how you use our services. If you purchase a product or service from Saharagroup site and you wish to exercise these rights over information about your purchase, you need to directly contact the vendor you interacted with. Sarahgroup only serve as a processor on behalf of the vendor, and cannot decide how to process their information. As such, we can only forward your request to them to allow them to respond. We will of course help our vendors to fulfill these requests by giving them the tools to do so and by answering their questions. 
If you would like to designate an authorized agent to exercise your rights for you, please email us from the email address we have on file for you. If you email us from a different email address, we cannot determine if the request is coming from you and will not be able to accommodate your request. In your email, please include the name and email address of your authorized agent.
If you are not happy with our response to a request, you can contact us to resolve the issue. You also have the right to contact your local data protection or privacy authority at any time.
<br /><br />
Information sharing outside Canada
<br />
<br />
Whenever, we share information outside Canada, the information is subject to the laws of the countries where we send it. In these situations, we take necessary steps to protect your information, and we try to only send your information to countries that have strong data protection laws. At times, we may be legally required to share your personal information and have to do so. 
<br /><br />
Uses and benefits of Cookies
<br />
<br />
Sarahgroup and its partner providers use cookies, web beacons, and similar tracking technologies to recognize you when you visit our ecommerce platform. A cookie may be placed on your device that collects information, about your online activities over time and across different sites. Keep in mind that cookies allow us to track use, infer browsing preferences, and improve and customize your browsing experience. You have the option to disable cookies but doing so may disrupt the services you received. To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit sites such as: www.allaboutcookies.org, or www.aboutcookies.org
<br /><br />
Information Protection
<br />
<br />
Our teams work tirelessly to protect your information, and to ensure the security and integrity of our platform. We also have independent auditors assess the security of our data storage and systems that process financial information. However, we all know that no method of transmission over the Internet, and method of electronic storage, can be 100% secure. This means we cannot guarantee the absolute security of your personal information.
<br /><br />
How you can reach us
<br />
<br />
If you would like to ask about, make a request relating to, or complain about how we process your personal information, you can contact us by email at sarahgroup365@gmail.com.
 
   

                  </p>
            
        </div>
    )
}

export default Terms

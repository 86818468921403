import Meat from "./Meat";
import books from "./Books";
import Clothing from "./Clothing";
import Cream from "./Cream";
import Services from "./Services";

const products = [
  ...Meat ,
  ...books,
  ...Clothing,
  ...Cream,
  ...Services,
];

export default products;

import React from "react";
import { Link } from "react-router-dom";
import styles from "../css/HomePage.module.css";
import s1Image from "../images/Sahara.jpg";

const HomePage = (props) => {
  return (
    <div className={styles.container}>
      <main>
        <section
          style={{ backgroundImage: `url(${s1Image})` }}
          className={styles.s1}
        >
          <h1 className={styles.s1Title}>Welcome To Sahara Market</h1>
          <p className={styles.s1Subtitle}>
           The Ultimate Local Online Shop
          </p>
          <Link className={styles.s1Link} to="/catalog">
            Shop now
          </Link>
        </section>
      </main>
    </div>
  );
};

export default HomePage;

import msiB450TomahawkMax from "../images/products/T-shirt.jpg";
import asusTufGaming from "../images/products/T-shirt1.jpg";
import asusRogStrixB450f from "../images/products/T-shirt2.jpg";
import prevMsiB450TomahawkMax from "../images/products/T-shirt.jpg";
import prevAsusTufGaming from "../images/products/T-shirt1.jpg";
import prevAsusRogStrixB450f from "../images/products/T-shirt2.jpg";
import msiB450TomahawkMaxGallery1 from "../images/products/T-shirt.jpg";
import asusTufGamingGallery1 from "../images/products/T-shirt1.jpg";
import asusRogStrixB450fGallery1 from "../images/products/T-shirt2.jpg";
import asusRogStrixB450fGallery2 from "../images/products/T-shirt3.jpg";

const motherboards = [
  {
    category: "Clothing",
    categoryId: "clothing",
    id: "msi-b450-tomahawk-max",
    name: "Light Blue Shirt",
    price: 20.15,
    image: msiB450TomahawkMax,
    previewImage: prevMsiB450TomahawkMax,
    gallery: [
      msiB450TomahawkMaxGallery1,
    ],
  },
  {
    category: "Clothing",
    categoryId: "clothing",
    id: "asus-tuf-gaming-x570-plus-wifi",
    name: "4 T-shirt Bundle",
    price: 20.15,
    image: asusTufGaming,
    previewImage: prevAsusTufGaming,
    gallery: [
      asusTufGamingGallery1,
    ],
  },
  {
    category: "Clothing",
    categoryId: "clothing",
    id: "asus-rog-strix-b450-f-gaming",
    name: "3 T-shirt Bundle",
    price: 20.15,
    image: asusRogStrixB450f,
    previewImage: prevAsusRogStrixB450f,
    gallery: [
      asusRogStrixB450fGallery1,
      asusRogStrixB450fGallery2,
    ],
  },
];

export default motherboards;

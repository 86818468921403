import firebase from 'firebase'
var firebaseApp = firebase.initializeApp({
   
    apiKey
    : "AIzaSyDjbmZ06ALXDt_e7fkLDAhNZ75mO728U90",
    authDomain:
     "react-contact-664b4.firebaseapp.com",
    projectId:
     "react-contact-664b4",
    storageBucket:
     "react-contact-664b4.appspot.com",
    messagingSenderId:
     "720669805993",
    appId:
     "1:720669805993:web:cd039e5fb426e2660388b8"
   
  
});

var db = firebaseApp.firestore();
export { db };
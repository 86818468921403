import React from 'react';
import '../word.css'

const Privacy = () => {
  return (
    <div>

         <iframe className="word" src="https://docs.google.com/document/d/e/2PACX-1vROha_93HEMnM6uTI3_J1lFTmX1dj6mm7FHFxepPmreJdJbR-8wfyVgayKcBOSVag/pub?embedded=true"></iframe>


    </div>
  )
}

export default Privacy
